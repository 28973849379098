.container {
  position: absolute;
  padding: 9px 15px;
  opacity: 0;
  background-color: var(--background-secondary-color);
  border: 1px solid var(--border-color);
  width: fit-content;
  user-select: none;
  pointer-events: none;

  &:global(.size-small) {
    padding: 3px 12px;

    > p.label {
      font-size: 0.8rem;
    }
  }

  &:global(.position-top) {
    bottom: calc(100% + var(--space));

    &:global(.align-center) {
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
  &:global(.position-bottom) {
    top: calc(100% + var(--space));
    &:global(.align-center) {
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
  &:global(.position-left) {
    right: calc(100% + var(--space));
    &:global(.align-center) {
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }
  &:global(.position-right) {
    left: calc(100% + var(--space));
    &:global(.align-center) {
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }
  &:global(.position-center) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }

  &:global(.align-left) {
    left: var(--space);
  }
  &:global(.align-right) {
    right: var(--space);
  }
  &:global(.align-top) {
    top: 0;
  }
  &:global(.align-bottom) {
    bottom: 0;
  }

  // &:global(.align-center) {
  // }

  > p.label {
    font-size: 1rem;
    text-align: left;
    white-space: nowrap;
  }
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}
