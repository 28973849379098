@import '../../styles/_mixins.scss';

.fireworks_canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 6000;
}

.fireworks_controls {
  @include flexbox(row, space-between, stretch);
  gap: var(--space);

  // other higher elements

  .actions {
    @include flexbox(column, flex-end, center);
    gap: var(--space);
    width: fit-content;

    > button {
      height: 60px;
      width: 60px;
      font-size: 18px;
      border: 1px solid var(--border-color-alpha);
      @include border-radius(var(--border-radius));

      &.autoButton {
        background-color: var(--background-secondary-color);
      }

      &.launchButton {
        background-color: var(--new-year-red);

        * {
          color: var(--text-always-white);
        }
      }
    }
  }
}
