@import '../../styles/_mixins.scss';

.footer {
  @include flexbox(column, center, center);
  height: fit-content;
  padding: var(--space-2x) 0;
  width: 100%;
  background-color: var(--background-secondary-color);
  scroll-snap-align: end;

  > h2 {
    color: var(--text-secondary-color);
  }

  > .social {
    @include flexbox(row, center, center);
    gap: var(--space);

    > a {
      font-size: 1.25rem;

      &:hover {
        * {
          color: var(--text-color);
        }
      }

      * {
        color: var(--text-secondary-color);
      }
    }
  }

  > p {
    font-size: 0.875rem;
    color: var(--text-secondary-color);
  }
}
