@import '../../styles/mixins';

.shortcutUrlGenerator {
  @include flexbox(column, center, center);
  height: 100%;
  padding: 24px;
  padding-top: 84px;
  gap: 12px;

  > input {
    width: 100%;
    max-width: 480px;
    padding: 12px 18px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }

  > button {
    color: var(--text-color);
    background-color: var(--background-secondary-color);
    padding: 8px 16px;
    border: 1px solid var(--border-color);

    &:not([disabled]):hover {
      color: var(--text-always-white);
      background-color: var(--link-hover-color);
    }

    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }

  > p {
    color: var(--text-color);
  }
}
