@import '../../styles/_mixins.scss';

.laboratory_container {
  @include page-container;

  @include respond-to('phone') {
    padding-top: 72px;
  }

  .laboratory_content {
    @include page-container-content(var(--page-max-width-l));

    .header {
      width: 100%;
      margin-bottom: 32px;

      p {
        color: var(--text-secondary-color);
      }
    }

    .tools_grid {
      @include flexbox(row, start, stretch);
      flex-wrap: wrap;
      gap: 12px;
      width: 100%;

      a.tool_card {
        @include flexbox(column, space-between, start);
        width: calc(50% - 6px);
        padding: 15px 18px;
        border: 1px solid var(--border-color);
        @include border-radius(var(--border-radius-m));
        text-decoration: none;

        @include respond-to('phone') {
          width: 100%;
        }

        > .tool_card_header {
          @include flexbox(row, space-between, start);
          margin-bottom: 72px;
          width: 100%;
          height: 48px;
          height: fit-content;

          > .tool_icon {
            > svg {
              z-index: -1;
              position: absolute;
              top: 0;
              left: 0;
              width: 80px;
              height: 80px;
              opacity: 0.1;
            }
          }

          > .tags {
            align-self: flex-end;
            justify-self: flex-end;
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;

            .tag {
              font-size: 0.75rem;
              padding: 0.25rem 0.5rem;
              background-color: var(--background-secondary-color);
              border-radius: 4px;
              color: var(--background-always-white);
            }
          }
        }

        > .tool_content {
          width: 100%;
          height: fit-content;

          > h2 {
            font-size: 1.2rem;
            font-weight: 600;
            margin-top: 12px;
          }
          > p {
            font-size: 0.875rem;
            margin: 0;
            color: var(--text-secondary-color);
          }
        }
      }
    }
  }
}
