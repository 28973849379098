@import '../../styles/_mixins.scss';

.calendar {
  @include page-container;
  width: 100%;
  height: calc(100% - 84px);
  padding: 24px;
  padding-top: 0;
  padding-bottom: 0;

  @include respond-to('phone') {
    padding: 0;
  }

  :global {
    .fc {
      width: 100%;
      height: 100%;
    }

    .fc-header-toolbar {
      height: 36px;
      margin-bottom: var(--space-1-5x) !important;

      @include respond-to('phone') {
        padding: 0 var(--space-1-5x);
        align-items: end;
      }

      .fc-toolbar-chunk:last-child {
        min-width: fit-content;
        height: 36px;
        min-width: min-content;

        > button.fc-today-button {
          @include border-radius(var(--border-radius-m));
          border: none;

          &:disabled {
            display: none;
          }
        }

        > .fc-button-group {
          height: 100%;
          @include border-radius(var(--border-radius-m));
          overflow: hidden;

          button {
            @include flexbox(row, center, center);
            height: 100%;
            border: none;

            @include respond-to('phone') {
              padding: 0 9px !important;
              font-size: 0.8rem;
            }
          }
        }
      }
    }

    .fc-toolbar-title {
      font-size: 1.5rem;
    }

    .fc-event-title {
      color: var(--text-always-white);
    }

    span {
      color: var(--text-always-white);
    }
  }
}
