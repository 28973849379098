@import '../../../../styles/mixins';

.audioPlayerContainer {
  @include flexbox(column, center, center);
  height: fit-content;
  width: 100%;
  margin-bottom: 12px;
  padding: 12px 18px;
  background-color: var(--background-secondary-color);
  border: 1px solid var(--border-color);

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-to('desktop') {
    background-color: var(--background-color);
  }

  > p.audioName {
    margin: 0;
    padding-bottom: 6px;
    width: 100%;
    font-size: 1rem;
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > svg {
      font-size: 1.2rem;
      margin-right: 9px;
    }
  }

  > .audioPlayer {
    @include flexbox(row, center, center);
    width: 100%;
    height: 60px;

    > .controls {
      @include flexbox(row, center, center);
      width: fit-content;
      height: 100%;
      padding-top: 12px;
      margin-right: 12px;

      > button {
        @include flexbox(row, center, center);
        width: 24px;
        height: 100%;
        margin: 0 6px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: var(--text-color);

        &:hover {
          color: var(--link-hover-color);
        }

        > svg {
          font-size: 1.2rem;
        }

        &.playButton {
          width: 36px;
          height: 100%;

          > svg {
            font-size: 1.5rem;
          }
        }
      }
    }

    > .progressBar {
      @include flexbox(column, center, center);
      position: relative;
      width: 100%;
      height: 100%;

      > input[type='range'] {
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        background: transparent;

        &:focus {
          outline: none;
        }

        // line
        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 12px;
          cursor: pointer;
          background: var(--progress-line);
          border-radius: 6px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          -ms-border-radius: 6px;
          -o-border-radius: 6px;

          transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          -webkit-transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          -moz-transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          -ms-transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          -o-transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        }

        // dot
        &:hover {
          &::-webkit-slider-runnable-track {
            height: 18px;
            border-radius: 9px;
            -webkit-border-radius: 9px;
            -moz-border-radius: 9px;
            -ms-border-radius: 9px;
            -o-border-radius: 9px;
          }

          &::-webkit-slider-thumb {
            height: 24px;
            width: 12px;
            margin-top: -3px;
            background: var(--link-hover-color);
          }
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 12px;
          width: 12px;
          background: var(--pregress-dot);
          cursor: pointer;
          border-radius: 6px;
          -webkit-border-radius: 6px;
          -moz-border-radius: 6px;
          -ms-border-radius: 6px;
          -o-border-radius: 6px;
          transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          -webkit-transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          -moz-transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          -ms-transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
          -o-transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        }
      }

      > .timeDisplay {
        @include flexbox(row, space-between, center);
        position: absolute;
        bottom: 0;
        width: 100%;

        > span {
          font-size: 0.8rem;
          color: var(--text-secondary-color);
        }
      }
    }
  }

  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}
