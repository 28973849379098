@import '../../../../styles/mixins';

.projectContainer {
  @include flexbox(column);
  padding: 24px;
  padding-top: 84px;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @include respond-to('phone') {
    padding: 12px;
    padding-top: 72px;
    padding-bottom: 0;
  }
  @include respond-to('phone-landscape') {
    padding-top: 60px;
  }
  @include respond-to('desktop') {
    height: 100%;
  }

  // 展示區塊
  > .projectContent {
    @include flexbox(column, start, start);
    position: relative;
    padding: 0;
    padding-top: 12px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 977px;

    @include respond-to('phone-landscape') {
      @include flexbox(row-reverse, start, center);
      height: min-content;
      max-height: 100%;
    }

    @include respond-to('desktop') {
      @include flexbox(row-reverse, start, center);
      height: min-content;
      padding-top: 0;
      max-height: 100%;
      border: 1px solid var(--border-color);
      overflow: hidden;
      // box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);

      &.hasVideo {
        max-width: 1200px;

        > .mediaContainer {
          @include respond-to('desktop') {
            width: 100%;
          }
        }

        > .mediaInfo {
          @include respond-to('desktop') {
            width: 30%;
            min-width: 360px;
          }
        }
      }
    }

    // 媒體展示區塊
    > .mediaContainer {
      z-index: 10;
      @include flexbox(column, start, center);
      width: 100%;
      height: min-content;
      // min-height: fit-content;
      max-height: 50%;
      padding: 12px;
      overflow: hidden;
      overflow-y: scroll;
      background-color: var(--background-color);
      border: 1px solid var(--border-color);
      // box-shadow: 0 0 18px 9px var(--background-color);
      -ms-overflow-style: none; /* IE 和 Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      @include respond-to('phone-landscape') {
        height: fit-content;
        max-height: 100%;
        width: 60%;
      }
      @include respond-to('desktop') {
        width: 60%;
        padding: 12px;
        height: 100%;
        max-height: 100%;
        background-color: var(--background-secondary-color);
        border: none;
        border-radius: 0 28px 28px 0;
        -webkit-border-radius: 0 28px 28px 0;
        -moz-border-radius: 0 28px 28px 0;
        -ms-border-radius: 0 28px 28px 0;
        -o-border-radius: 0 28px 28px 0;
      }

      // YouTube iframe
      > iframe {
        margin-bottom: 12px;
        width: 100%;
        aspect-ratio: 16 / 9;

        &:last-child {
          margin-bottom: 0;
        }

        border-radius: 18px;
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        -ms-border-radius: 18px;
        -o-border-radius: 18px;
      }

      // web video
      > div.video {
        margin-bottom: 12px;
        width: 100%;
        height: min-content;
        min-height: min-content;
        // overflow: hidden;

        &:last-child {
          margin-bottom: 0;
        }

        > video {
          height: min-content;
          width: 100%;
          border: 1px solid var(--border-color);
          @include border-radius(var(--border-radius-m));
        }
      }

      // image
      > div {
        margin-bottom: 12px;
        width: 100%;
        height: auto;
        max-height: 480px;

        &:last-child {
          margin-bottom: 0;
        }

        > img {
          margin: 0;
          width: 100%;
          height: 100%;
          max-height: 240px;
          object-fit: cover;
          border: 1px solid var(--border-color);

          @include respond-to('tablet') {
            max-height: 360px;
          }
          @include respond-to('desktop') {
            max-height: 480px;
          }
          border-radius: 18px;
          -webkit-border-radius: 18px;
          -moz-border-radius: 18px;
          -ms-border-radius: 18px;
          -o-border-radius: 18px;
        }
      }

      // file
      > .fileContainer {
        display: flex;
        width: 100%;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        > .file {
          @include flexbox(row, start, center);
          width: 100%;
          padding: 12px 18px;
          min-height: 60px;
          background-color: var(--background-secondary-color);
          color: var(--text-color);
          border: 1px solid var(--border-color);
          cursor: pointer;

          &:hover {
            color: var(--link-hover-color);
            border: 1px solid var(--link-hover-color);
          }

          @include respond-to('desktop') {
            background-color: var(--background-color);
          }

          // icon
          > svg {
            color: inherit;
            font-size: 1.2rem;
            margin-right: 9px;
          }

          // file name
          > p {
            margin: 0;
            font-size: 1rem;
            color: inherit;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          border-radius: 18px;
          -webkit-border-radius: 18px;
          -moz-border-radius: 18px;
          -ms-border-radius: 18px;
          -o-border-radius: 18px;
        }
      }
      border-radius: 28px;
      -webkit-border-radius: 28px;
      -moz-border-radius: 28px;
      -ms-border-radius: 28px;
      -o-border-radius: 28px;
    }

    // 媒體資訊區塊
    > .mediaInfo {
      @include flexbox(column, start, start);
      padding: 18px 12px 24px 12px;
      width: 100%;
      height: auto;
      // max-height: 70%;
      overflow-y: auto;

      @include respond-to('phone-landscape') {
        padding: 0 12px 0 12px;
        // height: 100%;
        width: 40%;
        max-height: 100%;
      }
      @include respond-to('desktop') {
        @include flexbox(column, space-between, start);
        width: 40%;
        height: 100%;
        max-height: 100%;
        padding: 36px 24px 24px 24px;
      }

      > .mediaInfoHeader {
        padding: 0 6px;
        > h3 {
          position: sticky;
          top: 0;
          text-align: left;
          margin: 0;
          margin-bottom: 24px;
          background-color: var(--background-color);
          box-shadow: 0 0 18px 18px var(--background-color),
            0 -48px 18px 18px var(--background-color);
        }
        > p.description {
          color: var(--text-secondary-color);
          text-align: left;
          margin: 6px 0;
          white-space: pre-wrap;
        }
      }

      > .details {
        @include flexbox(column, space-between);
        width: 100%;
        height: fit-content;
        min-height: fit-content;
        // max-height: 100%;
        padding: 12px 15px;
        margin-top: 24px;
        border: 1px solid var(--border-color);
        // overflow: hidden;
        // overflow-y: auto;

        @include respond-to('desktop') {
          border-radius: 12px;
          -webkit-border-radius: 12px;
          -moz-border-radius: 12px;
          -ms-border-radius: 12px;
          -o-border-radius: 12px;
        }

        > .detailsItem {
          @include flexbox(row, start);
          width: 100%;
          margin: 3px 0;

          > p {
            text-align: left;
            margin: 0;
            font-size: 0.8rem;
            color: var(--text-secondary-color);

            &:first-child {
              min-width: 50%;
              width: 50%;
              font-weight: 600;
              color: var(--text-color);
            }
          }
        }
        border-radius: 18px;
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        -ms-border-radius: 18px;
        -o-border-radius: 18px;
      }
    }
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
  }
}
