@import '../../styles/_mixins.scss';

.snow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 6000;
}

.sliderContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: auto;
  padding: 0 3px;
  // border: 1px solid #0000003f;
  // background: var(--background-secondary-color);
  @include border-radius(var(--border-radius-l));

  input.slider {
    flex: 1;
    -webkit-appearance: none;
    outline: none;
    height: 32px;
    background: #ffffff00;

    // 滑塊
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      margin-top: 2px;
      width: 28px;
      height: 28px;
      border: 1px solid #ffffff3f;
      background: var(--primary-color);
      cursor: pointer;
      @include border-radius(14px);
    }
    // 滑塊軌道
    &::-webkit-slider-runnable-track {
      height: 32px;
      // background: var(--background-always-white);
      @include border-radius(16px);
    }
  }

  .sliderValue {
    min-width: 3em;
    text-align: right;
  }
}
