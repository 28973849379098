.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal_header {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 1.2rem;
  }
}

.close_button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: var(--text-color-secondary);
  padding: 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: var(--hover-color);
  }
}

.modal_content {
  padding: 1rem;
}

.schedule_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.schedule_item {
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--hover-color);
  }

  &.selected {
    border-color: var(--primary-color);
    background-color: var(--primary-color-light);
  }
}

.schedule_title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.schedule_time {
  color: var(--text-color-secondary);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.schedule_description {
  color: var(--text-color-secondary);
  font-size: 0.9rem;
}

.button_group {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.confirm_button,
.cancel_button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.confirm_button {
  background-color: var(--primary-color);
  color: white;

  &:hover:not(:disabled) {
    background-color: var(--primary-color-dark);
  }
}

.cancel_button {
  background-color: var(--background-color);
  color: var(--text-color);

  &:hover {
    background-color: var(--hover-color);
  }
}
