@import '../../styles/_mixins.scss';

.modal_overlay {
  @include flexbox(row, center, center);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1px);
}

.modal {
  position: relative;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  padding: var(--space-2x);
  overflow-y: auto;
  background-color: var(--background-color);
  border: 1px solid var(--border-color-alpha);
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.1);
  @include border-radius(var(--border-radius-l));
  @include transform-container(translateY(0));

  &_header {
    @include flexbox(row, space-between, center);
    height: 48px;
    margin-bottom: var(--space-2x);
    padding-bottom: var(--space);
    border-bottom: 1px solid var(--border-color);

    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 700;
      min-width: 180px;
    }
    .close_button {
      height: 100%;
      color: var(--text-secondary-color);
      border: none;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  // &_content {
  // }
}

.schedule_details {
  > div {
    strong {
      display: block;
      color: var(--text-secondary-color);
      margin-right: var(--space);
      min-width: fit-content;
    }
    p {
      min-width: 96px;
      text-align: right;
    }
  }

  &_block {
    @include flexbox(row, space-between, center);

    > select {
      height: 24px;
      padding: 0 var(--space-0-5x);
      color: var(--text-always-white);
      border: none;
      @include border-radius(var(--border-radius-m));

      &:disabled {
        opacity: 1;
      }
    }
  }
}

.editable {
  padding: var(--space-0-5x) var(--space);
  background-color: var(--background-secondary-color);
  @include border-radius(var(--border-radius-m));
  outline: none;

  &:focus {
    background-color: var(--background-tertiary-color);
  }
}

.button_group {
  @include flexbox(row, center, center);
  gap: var(--space);
  margin-top: var(--space-2x);

  button {
    padding: var(--space-0-5x) var(--space);
    cursor: pointer;
    @include border-radius(var(--border-radius-m));

    &.delete_button {
      color: var(--text-danger-color);
      border: 1px solid var(--text-danger-color);
    }
    &.edit_button {
      color: var(--text-secondary-color);
      border: 1px solid var(--text-secondary-color);
    }
    &.save_button {
      color: var(--text-color-reverse);
      background-color: var(--text-color);
    }
    &.cancel_button {
      color: var(--text-secondary-color);
      border: 1px solid var(--text-secondary-color);
    }
  }
}
