@import '../../styles/_mixins.scss';

.home_container {
  @include page-container;
  // scroll-snap-type: y mandatory;
  // scroll-padding-top: calc(var(--header-height) + var(--space));
  // scroll-behavior: smooth;

  > .home_content {
    @include flexbox(column, start, start);
    padding: 0 var(--space-2x);
    width: 100%;
    min-height: calc(100vh - var(--header-height));
    max-width: var(--page-max-width-l);

    > div {
      @include flexbox(column, center, start);
      height: calc(100vh - var(--header-height) - var(--space));
      scroll-snap-align: start;

      &.top_content {
        width: 100%;
        padding-bottom: var(--header-height);

        > p {
          margin: 0;
          color: var(--text-color);
        }
      }

      &.page_content {
        padding-top: var(--space-4x);
        height: fit-content;
        width: 100%;

        > .who_am_i {
          @include flexbox(row, center, start);
          width: 100%;

          @include respond-to('phone') {
            @include flexbox(column, center, start);
          }

          > .image_content {
            @include flexbox(column, center, start);
            width: fit-content;
            border-radius: 12px;
            margin-right: var(--space-2x);

            @include respond-to('phone') {
              width: 100%;
            }

            img {
              width: 128px;
              height: 128px;
              object-fit: cover;
              user-select: none;
              pointer-events: none;

              @include respond-to('phone') {
                width: 96px;
                height: 96px;
              }
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }
          }

          > .intro_content {
            width: 100%;

            @include respond-to('phone') {
              margin-top: var(--space-2x);
            }

            > h2 {
              margin-bottom: var(--space);
            }
            > p {
              margin: var(--space) 0;
            }

            > .h_scroll {
              @include flexbox(row, start, start);
              flex-wrap: wrap;
              padding-bottom: var(--space);
              margin: 0 var(--space-2x);
              width: 100%;
              overflow: hidden;

              @include respond-to('phone') {
                @include flexbox(row, start, stretch);
                flex-wrap: nowrap;
                padding: 0 var(--space-2x);
                padding-bottom: var(--space);
                margin: var(--space-2x) 0;
                width: calc(100% + var(--space-4x));
                overflow-x: auto;
                scroll-snap-type: x mandatory;
              }

              > .h_block {
                z-index: 100;
                @include flexbox(column, start, start);
                padding: var(--space-2x) calc(var(--space-2x) + 3px);
                margin: var(--space) 0;
                min-width: calc(100% - var(--space-8x));
                border: 1px solid var(--border-color);
                background-color: var(--background-color);
                scroll-snap-align: center;

                @include respond-to('phone') {
                  // position: sticky;
                  // right: 0;
                  margin: 0;
                  margin-right: var(--space-2x);
                  height: fit-content;
                  // max-height: calc(100vh - var(--header-height) * 2);
                  min-width: calc(100% - var(--space-2x));
                  scroll-snap-align: center;
                  // overflow-y: auto;

                  &:last-child {
                    margin-right: 0;
                  }

                  @for $i from 1 through 5 {
                    &:nth-child(#{$i}) {
                      z-index: calc(100 - ($i - 1) * 10);
                    }
                  }
                }

                > p {
                  font-size: 0.9rem;
                }
                border-radius: 24px;
                -webkit-border-radius: 24px;
                -moz-border-radius: 24px;
                -ms-border-radius: 24px;
                -o-border-radius: 24px;
              }
              transform: translateX(calc(0px - var(--space-2x)));
              -webkit-transform: translateX(calc(0px - var(--space-2x)));
              -moz-transform: translateX(calc(0px - var(--space-2x)));
              -ms-transform: translateX(calc(0px - var(--space-2x)));
              -o-transform: translateX(calc(0px - var(--space-2x)));
            }
          }
        }
      }
    }
  }
}
